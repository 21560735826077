/* Custom */

.step {
    &__hr {
        border: solid 2px $light;
    }
    &__number {
        display: inline-block;
        text-align: center;
        width: 30px;
        height: 30px;
        border: solid 1px $light;
        border-radius: 50%;
        line-height: 1.6rem;
        font-size: 1.2rem;
        font-weight: 300;
        color: $light2;
        background-color: $light;
    }
    &__label {
        padding: 0 10px;
        color: #ddd;
        font-size: 1.2rem;
    }
    &__title {
        min-height: 50px;
        &__text {
            @extend .float-left;
            color: $dark;
            font-size: 1.4rem;
        }
        small {
            font-size: 0.8rem;
            color: $light2;
            margin-top: -5px;
            display: inline-block
        }
        &__search {
            @extend .btn,
            .float-right;
            border: 1px solid $light;
            background-color: #fbfbfb;
            border-radius: 20px;
            text-align: left;
            min-width: 200px;
            padding: 6px 15px;
            margin-top: 8px;
            &__icon {
                @extend .float-right;
                margin-top: 12px;
                border-radius: 20px;
                background-color: $primary;
                text-align: center;
                padding: 7px 10px 6px 10px;
                margin-right: -196px;
            }
        }
        &__button {
            @extend .btn,
            .float-right,
            .btn-outline-primary,
            .radius-30,
            .px-3,
            .py-1;
            text-align: left;
            min-width: 200px;
            margin-top: 8px;
            &__icon {
                @extend .float-right,
                .radius-30;
                margin-top: 1px;
            }
        }
    }
    &__body {
        margin: 15px 2px 5px 2px;
        max-height: calc(100vh - 400px);
        overflow-y: auto;
        overflow-x: hidden;
        float: left;
        width: 100%;
        .checkbox {
            width: 16px;
            height: 16px;
            margin-right: 5px;
            vertical-align: sub;
        }
        &__personality {
            background-color: lightblue;
            color: $dark;
            text-align: center;
            padding: 25px 10px;
            margin: 5px 0;
            border-radius: 40px;
        }
        &__button {
            @extend .btn,
            .float-right,
            .btn-outline-primary,
            .radius-30,
            .px-3,
            .py-1;
            text-align: left;
            min-width: 200px;
            &__icon {
                @extend .float-right,
                .radius-30;
                line-height: 1.2rem;
            }
        }
    }
    &__rightcard {
        border-radius: 10px;
        overflow: hidden;
        &__title {
            background-color: $primary;
            text-align: center;
            font-size: 1.2rem;
            padding: 5px 10px;
        }
        &__body {
            background-color: $light;
            padding: 10px;
            color: $dark;
            height: 344px;
            overflow-y: auto;
        }
    }
    &__button {
        @extend .btn,
        .btn-outline-primary,
        .radius-30,
        .px-3,
        .py-1;
    }
    &__container {
        &__bar {
            @extend .p-4,
            ,
            .pt-3,
            .mb-2,
            .radius-30;
            background-color: white;
        }
        &__form {
            @extend .p-4,
            .mb-0;
            background-color: white;
            border-radius: 30px 30px 0 0;
        }
        &__buttons {
            @extend .pb-4,
            .px-4;
            background-color: white;
            border-radius: 0 0 30px 30px;
        }
    }
}

.active {
    .step {
        &__hr {
            border: solid 2px $primary;
            opacity: 0.6;
        }
        &__number {
            border: solid 1px $primary;
            color: $primary;
            background-color: $light;
        }
        &__label {
            color: $primary;
        }
    }
}

.done {
    .step {
        &__hr {
            border: solid 2px $primary;
            opacity: 1;
        }
        &__number {
            border: solid 1px $primary;
            color: $light;
            background-color: $primary;
        }
        &__label {
            color: $primary;
        }
    }
}

.choices {
    &__select {
        height: 31px;
        padding: 0 10px;
        font-size: 0.8rem;
    }
}

.custom-card {
    @extend .bg-white,
    .radius-30;
    padding: 30px;
}


/** Fin **/

.step {
    list-style: none;
    margin: .2rem 0;
    width: 100%;
}

.step .step-item {
    -ms-flex: 1 1 0;
    flex: 1 1 0;
    margin-top: 0;
    min-height: 1rem;
    position: relative;
    text-align: center;
}

.step .step-item:not(:first-child)::before {
    background: $primary;
    content: "";
    height: 2px;
    left: -50%;
    position: absolute;
    top: 9px;
    width: 100%;
}

.step .step-item a {
    color: #acb3c2;
    display: inline-block;
    padding: 20px 10px 0;
    text-decoration: none;
}

.step .step-item a::before {
    background: $primary;
    border: .1rem solid #fff;
    border-radius: 50%;
    content: "";
    display: block;
    height: .9rem;
    left: 50%;
    position: absolute;
    top: .2rem;
    transform: translateX(-50%);
    width: .9rem;
    z-index: 1;
}

.step .step-item.active a::before {
    background: #fff;
    border: .1rem solid $primary;
}

.step .step-item.active~.step-item::before {
    background: #e7e9ed;
}

.step .step-item.active~.step-item a::before {
    background: #e7e9ed;
}

//   .btn-primary, .badge-primary {
//     background: rgb(220,17,63);
//     background: linear-gradient(90deg,rgba(54,123,190,1) 26%, rgba(118,68,148,1) 63%,  rgba(220,17,63,1) 100%);
//     border: none;
//   }
//   .btn-warning {
//     background: linear-gradient(
//         90deg
//         , rgba(54,123,190,1) 26%, rgba(201,213,34,1) 63%, rgba(253,215,23,1) 100%);
//             color: white;
//     border: none;
//   }
.select2-dropdown {
    border-radius: 10px !important;
}

.select2-selection--single {
    border-radius: 15px !important;
    height: 1.2rem !important;
    margin-top: -3px;
}

.select2-selection__rendered {
    line-height: 1rem !important;
    font-size: 0.8rem !important;
    margin-top: -7px !important;
}

.select2-selection__arrow {
    height: 1rem !important;
}

.select2-search__field {
    border: 1px solid #aaa;
    height: 1.2rem;
    font-size: 0.8rem;
    border-radius: 15px;
}

.select2-results__option[aria-selected] {
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 2px 10px;
}

.choices_tr {
    height: 40px !important;
}