.dataTables_wrapper{


    tr td:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding-left: 10px;
    }
    tr td:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding-right: 10px;
    }
    .dt-button, .buttons-collection{
        float: right !important;
        margin-left: 10px !important;
        float: right !important;
        margin-left: 10px !important;
        background: #fff;
        border: none;
        color: $light;
        margin: 5px;
    }
    .buttons-html5:hover{
        color: $light;
        margin: 5px;
        border: 1px solid;
    }

    .table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba($light,.12);
        border-radius: 5px !important;
    }

    .table-hover tbody tr:hover {
        background-color: #f5f5f5;
        td{
            color: $dark;
        }
        .btn{
            // background: white !important;
            i{
                color: $light !important;
            }
        }
    }
    .dataTables_length{
        margin-top: -40px;
        float: left;
    }
    div.dataTables_wrapper div.dataTables_filter {
        text-align: right !important;
    }

    td{
        vertical-align: middle;
    }
}
